.OurServices {
  .OurServices_header {
    background-color: var(--primary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15rem 10rem;
    margin-bottom: 15rem;
    .OurServices_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .OurServices_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .OurServices_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
  }
  .OurServices_servicesContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 5rem;
    margin-bottom: 15rem;
    @media (max-width: 768px) {
      width: 100%;
      gap: 3.5rem;
    }
  }
  .OurServices_services_slider {
    background-color: white;
    padding: 15rem 0;
    margin-bottom: 15rem;

    @media (max-width: 1250px) {
      padding: 2.5rem;
    }
    --swiper-pagination-color: var(--secondary);
    --swiper-navigation-color: var(--secondary);
    --swiper-navigation-sides-offset: 50px;
    --swiper-pagination-bottom: 0px;
  }

  .Service {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25rem;
    transition: all ease-in-out 0.2s;
    &:hover {
      cursor: pointer;
      transform: scale(1.05) !important;
    }
    @media (max-width: 768px) {
      width: 15rem;
    }
    .Service_icon {
      height: 17.5rem;
      width: 17.5rem;
      border-radius: 100%;
      background-color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2.5rem;
      @media (max-width: 768px) {
        width: 12.5rem;
        height: 12.5rem;
      }
      img {
        height: 16.5rem;
        width: 16.5rem;
        object-fit: cover;
        @media (max-width: 768px) {
          width: 11.5rem;
          height: 11.5rem;
        }
      }
    }
    .Service_title {
      color: var(--primary);
      text-transform: uppercase;
      text-align: center;
      font-size: 2.5rem;
      line-height: 2.5rem;
      font-weight: bold;
    }
  }

  .ServiceDetails {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 5rem;
    margin: 0rem 20rem;
    margin-bottom: 10rem;
    @media (max-width: 1250px) {
      flex-direction: column;
      margin: 0rem;
      gap: 2.5rem;
    }
    .ServiceDetails_left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 0.75;
      background-color: var(--primary);
      padding: 10rem 7.5rem;
      .ServiceDetails_left_icon {
        height: 25rem;
      }
      .ServiceDetails_left_heading {
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: capitalize;
        text-align: center;
        color: var(--white);
      }
      .ServiceDetails_left_underline {
        margin: 3.5rem 0rem;
        height: 0.75rem;
        width: 15%;
        background-color: var(--secondary);
      }
      .ServiceDetails_left_description {
        font-size: 2.25rem;
        line-height: 3rem;
        text-align: center;
        color: white;
        white-space: pre-wrap;
      }
    }

    .ServiceDetails_right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1.75;
      padding: 7.5rem 0;
      @media (max-width: 1250px) {
        padding: 2.5rem 0 5rem 0;
      }
      .ServiceDetails_right_description {
        font-size: 2.25rem;
        line-height: 3rem;
        font-weight: 600;
        text-align: center;
        color: var(--primary);
        white-space: pre-wrap;
        width: 75%;
        margin-bottom: 2.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: 1250px) {
          width: 80%;
        }
        @media (max-width: 768px) {
          width: 90%;
        }
      }
      .ServiceDetails_button {
        font-size: 2.25rem;
      }
    }
  }
}
