.RefundPolicy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15rem;
  .RefundPolicy_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15rem 0rem;
    background-color: var(--primary);
    .RefundPolicy_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .RefundPolicy_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .RefundPolicy_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .RefundPolicy_header_description {
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      width: 70%;
      @media (max-width: 768px) {
        width: 85%;
      }
    }
  }

  .RefundPolicy_procedures {
    background-color: var(--primary);
    width: 70%;
    border-radius: 5rem;
    margin: 15rem auto;
    padding: 10rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    @media (max-width: 1000px) {
      width: 80%;
      padding: 7.5rem;
      gap: 3.5rem;
    }
    @media (max-width: 768px) {
      width: 90%;
      padding: 5rem;
    }
    .RefundPolicy_procedure {
      display: flex;
      gap: 3.5rem;
      @media (max-width: 768px) {
        gap: 2.5rem;
      }
      .RefundPolicy_procedure_arrow {
        height: 2.5rem;
      }
      .RefundPolicy_procedure_content {
        font-size: 2.5rem;
        line-height: 3.25rem;
        color: white;
      }
    }
  }
  .RefundPolicy_bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    .RefundPolicy_bottom_description {
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 3.25rem;
      color: var(--primary);
      text-align: center;
      width: 70%;
      @media (max-width: 1000px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .RefundPolicy_bottom_separator {
      height: 0.75rem;
      width: 5vw;
      background-color: var(--secondary);
    }
  }
}
