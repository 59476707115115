.AboutUs {
  .Content {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    padding-bottom: 10rem;
    @media (max-width: 1000px) {
      gap: 10rem;
    }
  }
  .Row {
    display: flex;
    align-items: stretch;
    width: 100%;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 10rem;
    }
    .Row_leftComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: var(--white);
      @media (max-width: 1000px) {
        width: 100%;
        background-color: var(--off-white);
        &:has(.Image) {
          display: none;
        }
      }
    }
    .Row_rightComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      background-color: var(--white);
      @media (max-width: 1000px) {
        width: 100%;
        background-color: var(--off-white);
        &:has(.Image) {
          display: none;
        }
      }
    }
  }

  .TextContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem;
    @media (max-width: 1000px) {
      padding: 10rem 5rem;
    }
    .TextContent_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 5rem;
      .TextContent_heading_text {
        font-size: 5rem;
        line-height: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
      .TextContent_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .TextContent_description {
      font-size: 2.5rem;
      line-height: 3.5rem;
      font-weight: 600;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
    }
  }

  .Image {
    width: 100%;
    height: 100%;
    background-color: var(--off-white);
    object-fit: cover;
    object-position: center;
    transition: all ease-in-out 0.5s;
  }
}
