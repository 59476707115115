.ContactUs {
  .Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .Content_header {
      background-color: var(--primary);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15rem 10rem;
      .Content_header_heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .Content_header_heading_text {
          font-size: 5rem;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          color: var(--white);
          margin-bottom: 1.5rem;
        }
        .Content_header_heading_underline {
          height: 0.75rem;
          width: 65%;
          background-color: var(--secondary);
        }
      }
    }
  }
  .Content_contactUs {
    width: 100%;
  }
}
