.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;
  background-color: var(--primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
  .Footer_logo {
    height: 10rem;
    margin-bottom: 5rem;
    transition: all ease-in-out 0.2s;
    &:hover {
      transform: scale(1.05);
    }
  }
  .Footer_links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2.5rem;
    margin-bottom: 5rem;
    @media (max-width: 1000px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 2.5rem 5rem;
    }
    @media (max-width: 768px) {
      width: 75%;
    }
    .Footer_separator {
      width: 2.5rem;
      height: 0.25rem;
      background-color: var(--secondary);
      -webkit-transform: rotate(90deg); /* WebKit */
      -moz-transform: rotate(90deg); /* Mozilla */
      -o-transform: rotate(90deg); /* Opera */
      -ms-transform: rotate(90deg); /* Internet Explorer */
      transform: rotate(90deg);
      @media (max-width: 1000px) {
        width: 7.5rem;
        height: 0.05rem;
        display: none;
        -webkit-transform: rotate(0deg); /* WebKit */
        -moz-transform: rotate(0deg); /* Mozilla */
        -o-transform: rotate(0deg); /* Opera */
        -ms-transform: rotate(0deg); /* Internet Explorer */
        transform: rotate(0deg);
      }
    }
    .Footer_link {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: white;
      font-size: 1.75rem;
      font-weight: 500;
      transition: all ease-in-out 0.2s;
      img {
        height: 2.5rem;
        width: 2.5rem;
      }
      a {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--white);
        color: white;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .Footer_bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.5rem;
    flex-wrap: wrap;
    @media (max-width: 1100px) {
      align-items: flex-start;
      flex-direction: column;
      gap: 2.5rem;
    }
    .Footer_bottom_socialIcons {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex: 1;
      a {
        font-size: 0;
        img {
          height: 3rem;
          width: 3rem;
          transition: all ease-in-out 0.2s;
          &:hover {
            transform: scale(1.25);
          }
        }
      }
    }
    .Footer_bottom_address {
      font-size: 2rem;
      color: var(--white);
      text-align: center;
      flex: 2;
      span {
        font-weight: 600;
      }
      @media (max-width: 1100px) {
        text-align: left;
      }
    }
    .Footer_bottom_copyright {
      font-size: 2rem;
      color: var(--white);
      flex: 1;
      text-align: right;
      @media (max-width: 1100px) {
        margin-top: 3.5rem;
        text-align: left;
      }
    }
  }
}
