.CookiesPolicy {
  background-color: var(--primary);
  .CookiesPolicy_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15rem 0rem;
    .CookiesPolicy_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .CookiesPolicy_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .CookiesPolicy_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .CookiesPolicy_header_description {
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .CookiesPolicy_slider {
    margin: 0rem 10rem;
    background-color: white;

    --swiper-pagination-color: var(--secondary);
    --swiper-navigation-color: var(--secondary);
    --swiper-navigation-sides-offset: 50px;
    --swiper-pagination-bottom: 75px;

    @media (max-width: 768px) {
      margin: 0;
      --swiper-navigation-sides-offset: 25px;
      --swiper-pagination-bottom: 20px;
    }
  }

  .CookieSlide {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 15rem;
    @media (max-width: 768px) {
      margin: 10rem 5rem;
      margin-bottom: 10rem;
    }
    .CookieSlide_heading {
      font-size: 5rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      color: var(--primary);
      margin-bottom: 1.5rem;
    }
    .CookieSlide_underline {
      height: 0.75rem;
      width: 10%;
      margin-bottom: 2.5rem;
      background-color: var(--secondary);
    }
    .CookieSlide_description {
      font-size: 2rem;
      font-weight: 500;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      width: 90%;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }
}
