.OurCompanies {
  .Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 25rem;
    padding-bottom: 10rem;
    .Content_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: fit-content;
      margin-bottom: 15rem;
      @media (max-width: 850px) {
        margin-bottom: 0rem;
      }
      .Content_heading_text {
        font-size: 5rem;
        line-height: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
      .Content_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .Content_rows {
      display: flex;
      flex-direction: column;
      gap: 10rem;
      background-color: white;
    }
    @media (max-width: 850px) {
      gap: 10rem;
    }
  }

  .Row {
    display: flex;
    align-items: stretch;
    width: 100%;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    @media (max-width: 850px) {
      flex-direction: column;
      gap: 10rem;
    }
    .Row_firstComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55%;
      background-color: white;
      @media (max-width: 850px) {
        width: 100%;
        &:has(.Image) {
          display: none;
        }
      }
    }
    .Row_secondComponent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45%;
      @media (max-width: 850px) {
        width: 100%;
        &:has(.Image) {
          display: none;
        }
      }
    }
  }

  .Company {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5rem;
    @media (max-width: 850px) {
      padding: 10rem 5rem;
    }
    .Company_image {
      height: 30rem;
      margin-bottom: 3.5rem;
    }
    .Company_paragraph {
      font-size: 2.2rem;
      line-height: 3rem;
      font-weight: 600;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      span {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }

  .Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all ease-in-out 0.5s;
  }
}
