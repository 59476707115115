.AboutUs {
  .landing {
    height: 100svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
      background-attachment: unset;
    }
    .landing_image {
      height: 40rem;
    }
  }
}
