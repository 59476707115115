/* [1.] Borders */
.red {
  border: 1px solid red;
}
.green {
  border: 1px solid green;
}
.blue {
  border: 1px solid blue;
}

/* [2.] Responsive Container */
/* 1.) Mobile Mode (From 0px to 428px) */
@media only screen and (max-width: 428px) {
  .container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

/* 2.) Tablet Mode (From 429px to 768px) */
@media screen and (min-width: 429px) {
  .container {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
}

/* 3.) Laptop Mode (From 769px to 1280px) */
@media only screen and (min-width: 769px) {
  .container {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

/* 4.) Desktop Mode (From 1281px to 1920px) */
@media only screen and (min-width: 1281px) {
  .container {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
