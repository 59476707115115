.Home {
  .Testimonials {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20rem;
    padding-bottom: 20rem;
    @media (max-width: 768px) {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }
    .Testimonials_content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 10rem;
      padding-bottom: 10rem;
      background-color: var(--white);
      @media (max-width: 768px) {
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
      .Testimonials_content_heading {
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 2.5rem;
        white-space: pre-wrap;
      }
      .Testimonials_content_text {
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
        color: var(--primary);
        width: 60%;
        margin-bottom: 10rem;
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      .Testimonials_content_topSeparator {
        height: 0.75rem;
        width: 25rem;
        background-color: var(--secondary);
        margin-bottom: 7.5rem;
      }
      .Testimonials_content_slider {
        --swiper-pagination-color: var(--secondary);
        --swiper-navigation-color: var(--secondary);
        width: 70%;
        margin-bottom: 3.5rem;
        @media (max-width: 1000px) {
          width: 90%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        .Testimonials_content_slider_slide {
          display: flex;
          justify-content: center;
        }
      }
      .Testimonials_content_bottomSeparator {
        height: 0.35rem;
        width: 10rem;
        background-color: var(--secondary);
        margin-bottom: 3.5rem;
      }
    }
  }

  .Client {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10rem;
    @media (max-width: 768px) {
      width: 90%;
    }
    .Client_message {
      font-size: 2.5rem;
      line-height: 3.5rem;
      font-style: italic;
      text-align: center;
      color: var(--primary);
      margin-bottom: 2.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .Client_name {
      font-size: 3rem;
      font-weight: 700;
      text-align: center;
      color: var(--primary);
      margin-bottom: 0.5rem;
      text-transform: capitalize;
    }
    .Client_designation {
      font-size: 2rem;
      font-style: italic;
      text-align: center;
      color: var(--primary);
      text-transform: capitalize;
    }
  }
}
