.OurPartners {
  .Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .Content_header {
      background-color: var(--primary);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10rem 10rem;
      img {
        height: 20rem;
      }
    }
    .Content_info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 10rem;
      padding-bottom: 10rem;
      .Content_info_heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: fit-content;
        margin-bottom: 5rem;
        @media (max-width: 850px) {
          margin-bottom: 2.5rem;
        }
        .Content_info_heading_text {
          font-size: 5rem;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          color: var(--primary);
          margin-bottom: 1.5rem;
        }
        .Content_info_heading_underline {
          height: 0.75rem;
          width: 65%;
          background-color: var(--secondary);
        }
      }
      .Content_info_text {
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
        color: var(--primary);
        width: 80%;
        padding: 5rem;
        background-color: white;
        white-space: pre-wrap;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
