:root {
  /* Theme */
  --primary: #0b0146;
  --secondary: #cf0000;

  /* Hover */
  --primary-hover: #231a59;
  --secondary-hover: #d41a1a;

  /* Black and White */
  --black: #202020;
  --white: #ffffff;
  --black-hover: var(--primary);
  --white-hover: #f7f7f7;
  --off-white: #f8f9fa;
  --z-black: #404040;

  /* Alert */
  --error: #ff4d4f;
  --warning: #faad14;
  --success: #52c41a;
  --information: #1890ff;
  --disabled: #707070;
}
