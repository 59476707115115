.primaryButton {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 1.5rem 3.5rem;
  background-color: var(--white);
  text-decoration: none;
  color: var(--primary);
  font-size: 3rem;
  font-weight: 700;
  text-transform: capitalize;
  transition: all ease-in-out 0.2s;
}
.primaryButton:hover {
  background-color: var(--primary);
  color: var(--white);
  transform: scale(1.025);
}
