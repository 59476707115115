.Disclaimer {
  background-color: var(--primary);
  .Disclaimer_header,
  .Disclaimer_bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15rem 0rem;
    .Disclaimer_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .Disclaimer_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .Disclaimer_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .Disclaimer_header_description,
    .Disclaimer_bottom_description {
      font-size: 2.8rem;
      font-weight: 600;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .Disclaimer_middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    gap: 10rem;
    padding: 15rem 20rem;
    @media (max-width: 1250px) {
      padding: 15rem 10rem;
    }
    @media (max-width: 1000px) {
      gap: 2.5rem;
      flex-direction: column;
    }

    .Disclaimer_middle_leftSection {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .Disclaimer_middle_leftSection_image {
        height: 25rem;
      }
      .Disclaimer_middle_leftSection_separator {
        margin: 5rem 0rem;
        height: 0.75rem;
        width: 35%;
        background-color: var(--secondary);
        @media (max-width: 1000px) {
          width: 15%;
        }
      }
      .Disclaimer_middle_leftSection_content {
        font-size: 2.2rem;
        font-weight: 600;
        text-align: center;
        color: var(--primary);
        white-space: pre-wrap;
      }
    }

    .Disclaimer_middle_separator {
      height: 50rem;
      width: 2.5rem;
      background-color: var(--primary);
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .Disclaimer_middle_rightSection {
      font-size: 2rem;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
    }
  }
}
