/* MyriadPro - Regular */
@font-face {
  font-family: "MyriadPro";
  font-weight: 400;
  src: url("/src/common/fonts/MyriadPro-Regular.otf") format("opentype");
}
/* MyriadPro - Regular Italic*/
@font-face {
  font-family: "MyriadPro";
  font-weight: 400;
  font-style: italic;
  src: url("/src/common/fonts/MyriadPro-It.otf") format("opentype");
}
/* MyriadPro - Semi Bold */
@font-face {
  font-family: "MyriadPro";
  font-weight: 600;
  src: url("/src/common/fonts/MyriadPro-Semibold.otf") format("opentype");
}
/* MyriadPro - Semi Bold Italic */
@font-face {
  font-family: "MyriadPro";
  font-weight: 600;
  font-style: italic;
  src: url("/src/common/fonts/MyriadPro-SemiboldIt.otf") format("opentype");
}
/* MyriadPro - Bold */
@font-face {
  font-family: "MyriadPro";
  font-weight: 700;
  src: url("/src/common/fonts/MyriadPro-Bold.otf") format("opentype");
}
/* MyriadPro - Bold Italic */
@font-face {
  font-family: "MyriadPro";
  font-weight: 700;
  font-style: italic;
  src: url("/src/common/fonts/MyriadPro-BoldIt.otf") format("opentype");
}
