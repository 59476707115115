.Home {
  .OurCompanies {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20rem;
    padding-bottom: 20rem;
    background-color: rgb(242, 242, 242);
    @media (max-width: 768px) {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }
    .OurCompanies_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .OurCompanies_heading_text {
        font-size: 5rem;
        line-height: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
        text-align: center;
      }
      .OurCompanies_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .OurCompanies_slider {
      --swiper-pagination-color: var(--secondary);
      width: 100%;
      .OurCompanies_slider_slide {
        margin-bottom: 7.5rem;
        transition: all ease-in-out 0.25s;
      }
    }
  }

  .Company {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3.5rem 2.5rem;
    margin-bottom: 10rem;

    &:hover {
      background-color: rgb(0, 0, 0, 0.025);
    }
    &:hover .Company_button {
      background-color: var(--primary);
      color: var(--white);
    }
    .Company_image {
      height: 30rem;
      margin-bottom: 3.5rem;
    }
    .Company_paragraph {
      font-size: 2.2rem;
      line-height: 3rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 2.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--primary);
      white-space: pre-wrap;
      span {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
    .Company_button {
      color: var(--primary);
      background-color: rgb(242, 242, 242);
      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }
}
