.Loader {
  position: absolute;
  z-index: 1000;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  overflow: hidden;
  img {
    height: 25rem;
  }
}
