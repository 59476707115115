.PrivacyPolicy {
  background-color: var(--primary);
  .PrivacyPolicy_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15rem 0rem;
    .PrivacyPolicy_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .PrivacyPolicy_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .PrivacyPolicy_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .PrivacyPolicy_header_description {
      font-size: 2.8rem;
      font-weight: 600;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .PrivacyPolicy_slider {
    background-color: white;
    padding: 15rem 0;
    @media (max-width: 1250px) {
      padding: 2.5rem;
    }
    --swiper-pagination-color: var(--secondary);
    --swiper-navigation-color: var(--secondary);
    --swiper-navigation-sides-offset: 50px;
    --swiper-pagination-bottom: 0px;
  }
}

.PrivacyPolicySlide {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 5rem;
  margin: 0rem 20rem;
  margin-bottom: 10rem;

  @media (max-width: 1250px) {
    flex-direction: column;
    margin: 0rem;
    gap: 2.5rem;
  }
  .PrivacyPolicySlide_left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 0.75;
    background-color: var(--primary);
    padding: 10rem 7.5rem;
    .PrivacyPolicySlide_left_icon {
      height: 10rem;
      margin-bottom: 5rem;
    }
    .PrivacyPolicySlide_left_heading {
      font-size: 3.5rem;
      font-weight: 700;
      text-transform: capitalize;
      text-align: center;
      color: var(--white);
    }
    .PrivacyPolicySlide_left_underline {
      margin: 3.5rem 0rem;
      height: 0.75rem;
      width: 15%;
      background-color: var(--secondary);
    }
    .PrivacyPolicySlide_left_description {
      font-size: 2.25rem;
      line-height: 3rem;
      text-align: center;
      color: white;
      white-space: pre-wrap;
    }
  }

  .PrivacyPolicySlide_right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1.75;
    padding: 7.5rem 0;
    @media (max-width: 1250px) {
      padding: 2.5rem 0 5rem 0;
    }
    .PrivacyPolicySlide_right_description {
      font-size: 2.25rem;
      line-height: 3rem;
      font-weight: 600;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      width: 75%;
      @media (max-width: 1250px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
}
