.Portal {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--primary);
  padding: 20rem 15rem;
  @media (max-width: 768px) {
    padding: 10rem 7.5rem;
  }
  .Portal_heading {
    width: fit-content;
    padding: 2.5rem 4rem;
    border: 0.75rem solid var(--white);
    margin-bottom: 5rem;
    :hover {
      transform: scale(1.05);
    }
    @media (max-width: 768px) {
      padding: 1.5rem 3rem;
    }
    .Portal_heading_text {
      font-family: "MyriadPro";
      font-size: 6rem;
      line-height: 6rem;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--white);
      text-align: center;
      @media (max-width: 768px) {
        font-size: 5rem;
        line-height: 5rem;
      }
    }
  }
  .Portal_paragraph {
    font-family: "MyriadPro";
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 600;
    width: 52.5%;
    text-align: center;
    color: var(--white);
    @media (max-width: 1000px) {
      width: 65%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
