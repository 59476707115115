.modal-overlay {
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 80%;
  animation: modal-open 0.2s ease-in-out;
  // animation: modal-close 0.2s ease-in-out;
}
.modal {
  z-index: 1000000;
  min-width: 100rem;
  position: fixed;
  background-color: var(--primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5rem;
  padding: 5rem;
  animation: modal-open 0.2s ease-in-out;
  // animation: modal-close 0.2s ease-in-out;
}
.modal_header_close {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  border-radius: 100rem;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
  transition: 0.2s;
}
.modal_header_close:hover {
  background-color: var(--primary-hover);
}
.modal_header_close > img {
  width: 2.5rem;
  transition: 0.2s;
}
.modal_header_close:hover > img {
  transform: scale(1.1);
}

body.modal-open {
  overflow: hidden;
}

@keyframes modal-open {
  from {
    opacity: 0;
    /* transform: scale(0.8); */
  }
  to {
    opacity: 0.8;
    /* transform: scale(1); */
  }
}

@keyframes modal-close {
  from {
    opacity: 0.8;
    /* transform: scale(0.8); */
  }
  to {
    opacity: 0;
    /* transform: scale(1); */
  }
}

@media screen and (max-width: 768px) {
  .modal {
    min-width: 75rem;
  }
}
@media screen and (max-width: 550px) {
  .modal {
    min-width: 60rem;
  }
}
