.GoldenVisa {
  .GoldenVisa_header {
    background-color: var(--primary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15rem 10rem;
    margin-bottom: 15rem;
    @media (max-width: 768px) {
      margin-bottom: 7.5rem;
    }
    .GoldenVisa_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .GoldenVisa_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .GoldenVisa_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
  }
  .GoldenVisa_section1 {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 10rem;
    margin-bottom: 10rem;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 5rem;
    }
    .GoldenVisa_section1_left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 0.2;
      background-color: var(--primary);
      padding: 5rem;
      @media (max-width: 1250px) {
        padding: 2.5rem;
      }
      .GoldenVisa_section1_left_icon {
        height: 25rem;
      }
      .GoldenVisa_section1_left_heading {
        font-size: 3.5rem;
        font-weight: 700;
        text-transform: capitalize;
        text-align: center;
        color: var(--white);
        margin-bottom: 3.5rem;
      }
      .GoldenVisa_section1_left_underline {
        margin-bottom: 3.5rem;
        height: 0.75rem;
        width: 15%;
        background-color: var(--secondary);
      }
    }
    .GoldenVisa_section1_right {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 0.8;
      .GoldenVisa_section1_right_heading {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: var(--primary);
        white-space: pre-wrap;
        margin-bottom: 1rem;
      }
      .GoldenVisa_section1_right_description {
        font-size: 2.25rem;
        line-height: 3rem;
        font-weight: 500;
        color: var(--primary);
        white-space: pre-wrap;
      }
    }
  }

  // .GoldenVisa_section2 {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   background-color: var(--off-white);
  //   padding-top: 10rem;
  //   padding-bottom: 10rem;
  //   margin-bottom: 10rem;
  //   .GoldenVisa_section2_image {
  //     height: 10rem;
  //     margin-bottom: 7.5rem;
  //     @media (max-width: 768px) {
  //       height: 6.5rem;
  //     }
  //   }
  //   .GoldenVisa_section2_heading {
  //     font-size: 3rem;
  //     line-height: 3.5rem;
  //     font-weight: 700;
  //     color: var(--primary);
  //     white-space: pre-wrap;
  //     margin-bottom: 1rem;
  //     width: 70%;
  //     text-align: center;
  //     @media (max-width: 1000px) {
  //       width: 90%;
  //     }
  //     @media (max-width: 768px) {
  //       width: 100%;
  //     }
  //   }
  //   .GoldenVisa_section2_description {
  //     font-size: 2.25rem;
  //     line-height: 3rem;
  //     font-weight: 500;
  //     color: var(--primary);
  //     text-align: center;
  //     white-space: pre-wrap;
  //     width: 60%;
  //     @media (max-width: 1000px) {
  //       width: 90%;
  //     }
  //     @media (max-width: 768px) {
  //       width: 100%;
  //     }
  //   }
  // }

  .GoldenVisa_section3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
    margin-bottom: 10rem;
    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 5rem;
    }
    .GoldenVisa_section3_left {
      display: flex;
      flex: 0.2;
      object-fit: contain;
      height: 35rem;
      padding: 5rem;
      @media (max-width: 1000px) {
        flex: auto;
        padding: 0rem;
      }
    }
    .GoldenVisa_section3_right {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 0.8;
      .GoldenVisa_section3_right_heading {
        font-size: 3rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: var(--primary);
        white-space: pre-wrap;
        margin-bottom: 1rem;
      }
      .GoldenVisa_section3_right_description {
        font-size: 2.25rem;
        line-height: 3rem;
        font-weight: 500;
        color: var(--primary);
        white-space: pre-wrap;
        margin-bottom: 2.5rem;
      }
    }
  }

  .GoldenVisa_section4 {
    width: 100%;
    margin-bottom: 10rem;
  }

  .GoldenVisa_section5 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10rem;
    .GoldenVisa_section5_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .GoldenVisa_section5_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
      .GoldenVisa_section5_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .GoldenVisa_section5_description {
      font-size: 2.8rem;
      font-weight: 600;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .GoldenVisa_section6 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--off-white);
    padding-top: 20rem;
    padding-bottom: 20rem;
    .GoldenVisa_section6_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 10rem;
      .GoldenVisa_section6_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
      .GoldenVisa_section6_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .GoldenVisa_section6_title {
      font-size: 2.8rem;
      font-weight: 700;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      margin-bottom: 0.75rem;
    }
    .GoldenVisa_section6_content {
      font-size: 2.8rem;
      font-weight: 600;
      color: var(--primary);
      white-space: pre-wrap;
      margin-bottom: 0.75rem;
      span {
        font-size: 2.8rem;
        font-weight: 400;
        color: var(--primary);
        white-space: pre-wrap;
      }
    }
    .GoldenVisa_section6_underline {
      height: 0.35rem;
      width: 1.5%;
      background-color: var(--secondary);
      margin: 3.5rem;
    }
    .GoldenVisa_section6_note {
      font-size: 2rem;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      margin-top: 3.5rem;
    }
  }
}
