.Home {
  .Services {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20rem;
    padding-bottom: 20rem;
    @media (max-width: 768px) {
      padding-top: 15rem;
      padding-bottom: 15rem;
    }
    .Services_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 5rem;
      .Services_heading_text {
        font-size: 5rem;
        line-height: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--primary);
        margin-bottom: 1.5rem;
      }
      .Services_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
    .Services_text {
      font-size: 3rem;
      font-weight: 400;
      text-align: center;
      color: var(--primary);
      margin-bottom: 7.5rem;
    }
    .Services_servicesContainer {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      gap: 5rem;
      @media (max-width: 768px) {
        width: 100%;
        gap: 3.5rem;
      }
    }
  }

  .Service {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 25rem;
    transition: all ease-in-out 0.2s;
    &:hover {
      transform: scale(1.05) !important;
    }
    @media (max-width: 768px) {
      width: 15rem;
    }
    .Service_icon {
      height: 17.5rem;
      width: 17.5rem;
      border-radius: 100%;
      background-color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2.5rem;
      @media (max-width: 768px) {
        width: 12.5rem;
        height: 12.5rem;
      }
      img {
        height: 16.5rem;
        width: 16.5rem;
        object-fit: cover;
        @media (max-width: 768px) {
          width: 11.5rem;
          height: 11.5rem;
        }
      }
    }
    .Service_title {
      color: var(--primary);
      text-transform: uppercase;
      text-align: center;
      font-size: 2.5rem;
      line-height: 2.5rem;
      font-weight: 600;
    }
  }
}
