.ServiceDetails {
  .ServiceDetails_header {
    background-color: var(--primary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15rem 10rem;
    margin-bottom: 15rem;
    @media (max-width: 768px) {
      margin-bottom: 7.5rem;
    }
    .ServiceDetails_header_heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .ServiceDetails_header_heading_text {
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: var(--white);
        margin-bottom: 1.5rem;
      }
      .ServiceDetails_header_heading_underline {
        height: 0.75rem;
        width: 65%;
        background-color: var(--secondary);
      }
    }
  }
  .ServiceDetails_content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ServiceDetails_content_top {
      width: 70%;
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 600;
      text-align: center;
      color: var(--primary);
      white-space: pre-wrap;
      margin-bottom: 15rem;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 7.5rem;
      }
    }

    .ServiceDetails_content_bottom {
      width: 80%;
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 10rem;
      margin-bottom: 10rem;
      @media (max-width: 1250px) {
        flex-direction: column;
        gap: 5rem;
        width: 100%;
      }
      .ServiceDetails_content_bottom_left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 0.2;
        background-color: var(--primary);
        padding: 5rem;
        @media (max-width: 1250px) {
          padding: 2.5rem;
        }
        .ServiceDetails_content_bottom_left_icon {
          height: 25rem;
        }
        .ServiceDetails_content_bottom_left_heading {
          font-size: 3.5rem;
          font-weight: 700;
          text-transform: capitalize;
          text-align: center;
          color: var(--white);
          margin-bottom: 3.5rem;
        }
        .ServiceDetails_content_bottom_left_underline {
          margin-bottom: 3.5rem;
          height: 0.75rem;
          width: 15%;
          background-color: var(--secondary);
        }
      }

      .ServiceDetails_content_bottom_right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 0.8;
        .ServiceDetails_content_bottom_right_description {
          font-size: 2.25rem;
          line-height: 3rem;
          font-weight: 600;
          text-align: center;
          color: var(--primary);
          white-space: pre-wrap;
          @media (max-width: 1250px) {
            width: 80%;
          }
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}
