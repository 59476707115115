.Home {
  .Hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    .Hero_landing {
      height: 100svh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10rem;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 768px) {
        background-attachment: unset;
      }
      .Hero_landing_image {
        height: 40rem;
      }
      .Hero_landing_downloadButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.5rem;
        a {
          img {
            height: 5.5rem;
            transition: all ease-in-out 0.2s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
    .Hero_line {
      height: 0.7rem;
      width: 20vh;
      background-color: var(--secondary);
      -webkit-transform: rotate(90deg) !important; /* WebKit */
      -moz-transform: rotate(90deg) !important; /* Mozilla */
      -o-transform: rotate(90deg) !important; /* Opera */
      -ms-transform: rotate(90deg) !important; /* Internet Explorer */
      transform: rotate(90deg) !important;
      bottom: 0;
      margin: 0rem 50rem;
      z-index: 999;
    }
    .Hero_aboutUs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100svh;
      width: 100%;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 768px) {
        background-attachment: unset;
      }
      .Hero_aboutUs_content {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 999;
        .Hero_aboutUs_content_heading {
          width: fit-content;
          padding: 2.5rem 4rem;
          border: 0.75rem solid var(--white);
          margin-bottom: 5rem;
          @media (max-width: 768px) {
            padding: 1.5rem 3rem;
          }
          .Hero_aboutUs_content_heading_text {
            font-family: "MyriadPro";
            font-size: 6rem;
            line-height: 6rem;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--white);
            text-align: center;
            @media (max-width: 768px) {
              font-size: 5rem;
              line-height: 5rem;
            }
          }
        }
        .Hero_aboutUs_content_paragraph {
          font-family: "MyriadPro";
          font-size: 2.5rem;
          line-height: 3.5rem;
          font-weight: 600;
          width: 62.5%;
          text-align: center;
          margin-bottom: 3.5rem;
          color: var(--white);
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .Hero_aboutUs_overlay {
        width: 100%;
        height: 100svh;
        position: absolute;
        background-color: rgba(90, 109, 148, 0.5);
      }
    }
  }
}
