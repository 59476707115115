.CommonContactUs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--off-white);
  padding-top: 10rem;
  padding-bottom: 10rem;
  @media (max-width: 768px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .CommonContactUs_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
    .CommonContactUs_heading_text {
      font-size: 5rem;
      line-height: 5rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      color: var(--primary);
      margin-bottom: 1.5rem;
      overflow: auto;
    }
    .CommonContactUs_heading_underline {
      height: 0.5rem;
      width: 45%;
      background-color: var(--primary);
    }
  }
  .CommonContactUs_content {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .CommonContactUs_content_form {
      display: flex;
      flex-direction: column;
      width: 60%;
      background-color: var(--white);
      padding: 3.5rem;
      gap: 2.5rem;
      @media (max-width: 768px) {
        width: 100%;
      }
      .CommonContactUs_content_form_button {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 1.5rem 3.5rem;
        background-color: var(--primary);
        text-decoration: none;
        color: var(--white);
        font-size: 3rem;
        font-weight: 700;
        text-transform: capitalize;
        cursor: pointer;
        transition: all ease-in-out 0.2s;
        &:hover {
          background-color: var(--primary-hover);
          color: var(--white);
          transform: scale(1.025);
        }
      }
    }
    .CommonContactUs_content_contactDetails {
      width: 40%;
      height: -webkit-fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      background-color: var(--white);
      padding: 3.5rem;
      @media (max-width: 768px) {
        width: 100%;
      }
      .CommonContactUs_content_contactDetails_group {
        .CommonContactUs_content_contactDetails_group_label {
          font-size: 2.5rem;
          line-height: 2.5rem;
          font-weight: 600;
          text-transform: capitalize;
          color: var(--primary);
          margin-bottom: 0.5rem;
        }
        .CommonContactUs_content_contactDetails_group_item {
          font-size: 2rem;
          line-height: 2.5rem;
          font-weight: 400;
          color: var(--primary);
          margin-bottom: 0.5rem;
          &:hover {
            color: var(--secondary);
          }
        }
      }
    }
  }
}
.CommonContactUs_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7.5rem;
  @media (max-width: 768px) {
    padding: 5rem 2.5rem;
  }
}
.CommonContactUs_modal_image {
  width: 15rem;
  margin-bottom: 2.5rem;
}
.CommonContactUs_modal_underline {
  background-color: var(--secondary);
  height: 0.75rem;
  border: none;
  width: 15rem;
  margin-bottom: 4.5rem;
}
.CommonContactUs_modal_heading {
  font-size: 3.4rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
}
.CommonContactUs_modal_message {
  font-size: 2.8rem;
  color: var(--white);
  text-align: center;
  width: 90%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
