.Navbar {
  position: fixed;
  width: 100%;
  transition: top 0.3s;
  z-index: 99999;
  .Navbar_header {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2.5rem 5rem;
    .Navbar_header_rightSection {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .Navbar_header_rightSection_toggleLanguage {
        display: flex;
        align-items: center;
        gap: 1rem;
        h3 {
          position: relative;
          font-size: 2.5rem;
          font-weight: 600;
          color: var(--secondary);
          padding: 1rem;
          border-radius: 10%;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: rgba(255, 0, 0, 0.075);
          }
        }

        .Navbar_header_rightSection_toggleLanguage_separator {
          width: 0.35rem;
          height: 2.5rem;
          background-color: var(--secondary);
          border-radius: 100rem;
        }
        .Navbar_header_rightSection_toggleLanguage_selected::after {
          content: "";
          height: 0.3rem;
          width: 1.5rem;
          border-radius: 2rem;
          background-color: var(--secondary);
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          margin-top: 1.75rem;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 3rem 3rem;
    }
    a {
      font-size: 0;
      .Navbar_header_logo_show {
        height: 12.5rem;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
      }
      .Navbar_header_logo_hide {
        height: 12.5rem;
        object-fit: cover;
        opacity: 0;
      }
    }
    .Navbar_header_rightSection_toggleDrawer {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
      height: 7.5rem;
      width: 7.5rem;
      cursor: pointer;
      .Navbar_header_rightSection_toggleDrawer_open,
      .Navbar_header_rightSection_toggleDrawer_close {
        height: 3.5rem;
        fill: var(--secondary);
        -webkit-user-select: none;
        user-select: none;
      }
      &:hover {
        background-color: rgba(255, 0, 0, 0.075);
      }
    }
  }
}

.Drawer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-color: var(--primary);
  gap: 5rem;
  padding: 5rem 5rem;
  @media (max-width: 768px) {
    padding: 7.5rem 3rem;
    justify-content: flex-start;
    height: 100dvh;
  }
  .Drawer_separator {
    width: 100%;
    height: 0.25rem;
    background-color: var(--secondary);
    margin-top: 12.5rem;
  }
  .Drawer_links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 3.5rem;
    width: 100%;
    -webkit-user-select: none;
    user-select: none;
    @media (max-width: 768px) {
      gap: 5rem;
      flex-direction: column;
      align-items: flex-start;
    }
    .Drawer_links_separator {
      width: 2.5rem;
      height: 0.25rem;
      background-color: var(--secondary);
      transform: rotate(90deg);
      @media (max-width: 768px) {
        transform: rotate(0deg);
        display: none;
      }
    }
    .Drawer_links_link {
      color: var(--white);
      font-size: 2.5rem;
      font-weight: 600;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      @media (max-width: 768px) {
        font-size: 2.75rem;
        width: 100%;
      }
      &:hover {
        color: var(--secondary);
        text-decoration: underline;
      }
    }
  }
}
