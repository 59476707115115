/* Text Input */
.textInput {
  flex: 1;
}
.textInput_Label {
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--primary);
  text-transform: capitalize;
}
.textInput_Field {
  font-weight: 400;
  font-size: 2.5rem;
  padding: 1.7rem 3rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.textInput_Field::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.textInput_Field:hover {
  border: 0.3rem solid rgba(0, 0, 0, 0.5);
}
.textInput_Field:focus {
  outline: 0rem;
  border: 0.3rem solid var(--primary);
}
.textInput_Error {
  font-size: 1.75rem;
  margin-top: 0.75rem;
  color: red;
}

/* Text Area Input */
.textAreaInput {
  flex: 1;
}
.textAreaInput_Label {
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--primary);
  text-transform: capitalize;
}
.textAreaInput_Field {
  font-weight: 400;
  font-size: 2.5rem;
  padding: 1.7rem 3rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  width: 100%;
  resize: none;
  transition: all 0.2s ease-in-out;
  outline: 0rem;
}
.textAreaInput_Field::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.textAreaInput_Field:hover {
  border: 0.3rem solid rgba(0, 0, 0, 0.5);
}
.textAreaInput_Field:focus {
  border: 0.3rem solid var(--primary);
}
.textAreaInput_Error {
  font-size: 1.75rem;
  margin-top: 0.75rem;
  color: red;
}

/* Phone Input */
.phoneInput {
  flex: 1;
}
.phoneInput_Label {
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--primary);
  text-transform: capitalize;
}
.phoneInput_Field {
  margin-top: 0.5rem !important;
  font-family: "MyriadPro" !important;
}
.phoneInput_Field_Input {
  border-radius: 0 !important;
  height: auto !important;
  padding-top: 1.7rem !important;
  padding-bottom: 1.7rem !important;
  font-weight: 400 !important;
  font-size: 2.5rem !important;
  width: 100% !important;
  border: 0.3rem solid rgba(0, 0, 0, 0.1) !important;
  padding-left: 75px !important;
  padding-right: 75px !important;
}
.phoneInput_Field_Button {
  border: 0.3rem solid rgba(0, 0, 0, 0.1) !important;
}
.phoneInput_Field_Button > .selected-flag {
  width: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
}
.phoneInput_Field_Button > .phoneInput_Field_Dropdown {
  width: auto !important;
}
.dial-code {
  margin-right: 15px !important;
}
.phoneInput_Error {
  font-size: 1.75rem;
  margin-top: 0.75rem;
  color: red;
}

/* Drop Down Menu */
.dropDownMenu {
  flex: 1;
}
.dropDownMenu_Label {
  font-size: 2.5rem !important;
  font-weight: 400;
  color: var(--primary);
  text-transform: capitalize;
}
.Dropdown-control {
  font-size: 2.5rem !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
  height: 100%;
  border: none;
  cursor: pointer;

  margin-top: 0.5rem;
  padding: 1.7rem 3rem;
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0rem;
  transition: all 0.2s ease-in-out;
}
.Dropdown-placeholder {
  width: 100%;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
}
.Dropdown-menu {
  font-size: 2.5rem !important;
  font-weight: 400;
  margin-top: 1rem;
  border-radius: 0rem;
  width: 100%;
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 1s;
  box-shadow: none;
  z-index: 1000;
  cursor: pointer;
  -webkit-box-shadow: 0px 25px 25px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 25px 25px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 25px 25px 5px rgba(0, 0, 0, 0.05);
}
.Dropdown-menu > .Dropdown-option {
  margin: 0.75rem;
  text-transform: capitalize;
}
.Dropdown-menu > .Dropdown-option:hover {
  background-color: #fafafa;
}
.Dropdown-arrow {
  margin-right: 3rem;
  margin-top: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
}
.Dropdown-control:hover {
  border: 0.3rem solid rgba(0, 0, 0, 0.5);
  transition: all 1s;
}
.Dropdown-control:focus {
  border: 0.3rem solid rgba(0, 0, 0, 1);
  transition: all 1s;
}
.dropDownMenu_Error {
  font-size: 1.75rem;
  margin-top: 0.75rem;
  color: red;
}
