.Home {
  .Application {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .Application_image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
